<template>
  <div>
    <form enctype="multipart/form-data" novalidate id="form">
      <div class="box">
        <div class="box-inner">
          <div class="dropzone" v-if="!isLoading">
            <input
              type="file"
              multiple
              :accept="allFiles ? '*' : 'image/*'"
              class="input-file"
              @change="
                handleChanges($event.target.name, $event.target.files);
                fileCount = $event.target.files.length;
              "
            />
            <ul class="box-notes">
              <li class="first">
                {{ allFiles ? "Drag a file here" : "Drag an image here" }}
              </li>
              <li class="second">or</li>
              <li class="third link">Browse files</li>
            </ul>
          </div>
          <div class="status" v-if="isLoading">
            <span class="loader">
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="spinner"
              >
                <circle
                  fill="none"
                  stroke="#454b54"
                  cx="15"
                  cy="15"
                  r="14"
                ></circle>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="output" v-if="isSuccess">
      <div class="background-img-css">
        <span>Preview</span>
        <pre>
          <img
  v-for="(item, index) in this.output"
  :key="index"
  v-bind:src="item.content"
  class="previewImage"
>
          <!-- <code v-for="(item, index) in this.output" :key="index">
          .{{item.name}} {background-image: url("{{item.content}}");}
          </code> -->
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
const INITIAL = 0,
  LOADING = 1,
  SUCCESS = 2,
  FAILED = 3;

export default {
  name: "FileUploader",

  data() {
    return {
      errors: null,
      status: null, // INITIAL, LOADING, SUCCESS, FAILED
      output: [],
    };
  },
  props: ["uploadFileReturnFileName", "allFiles"],
  computed: {
    isInitial() {
      return this.status === INITIAL;
    },
    isLoading() {
      return this.status === LOADING;
    },
    isSuccess() {
      return this.status === SUCCESS;
    },
    isFailed() {
      return this.status === FAILED;
    },
  },
  methods: {
    validateImg(file) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          //if (img.width === 100 && img.height === 100) {
            resolve(true);
          //} else {
          //  resolve(false);
          //}
        };
      });
    },
    handleChanges(fieldName, fileList) {
      debugger;
      this.reset(); // reset upload

      if (!fileList.length) return;
      this.status = LOADING;

      [].slice.call(fileList).map((file) => {
        var url = URL.createObjectURL(file);
        this.validateImg(file).then((valid) => {
          if (valid) {
            if (!this.uploadFileReturnFileName) {
              this.$emit("ImageDataChanged", file);
              this.$emit("ImageURLDataChanged", url);
            }
            this.status = SUCCESS;
            this.output.push({
              name: this.sanitizeFileName(file.name),
              content: url,
            });
            if (this.uploadFileReturnFileName) {
              this.status = LOADING;
              this.fileToBase64(file)
                .then(this.resolveAfterTime(1500))
                .then((res) => {
                  var data = {
                    base64: res,
                    fileName: file.name,
                    extension: file.name.split(".").pop(),
                  };

                  var payload = {
                    data: data,
                    success: (response) => {
                      this.$emit("ImageUploaded", response.data);
                      this.status = SUCCESS;
                    },
                    error: (error) => {
                      this.status = FAILED;
                    },
                  };
                  this.$store.dispatch("uploadFile", payload);
                });
            }
          } else {
            
          }
        });
      });
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let res = reader.result;
          resolve(res);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    sanitizeFileName(name) {
      return name
        .replace(/\.[^/.]+$/, "")
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
    },
    resolveAfterTime(time) {
      return (x) => {
        return new Promise((resolve) => setTimeout(() => resolve(x), time));
      };
    },
    reset() {
      this.status = INITIAL;
      this.errors = null;
      this.output = [];
    },
  },
};
</script>

<style scoped lang="scss">
.previewImage {
  max-height: 100px;
}
.box {
  padding: 10px;
  position: relative;

  .box-inner {
    color: #2c3e50;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    border: 2px dashed #86bdf9;
    border-radius: 10px;
    z-index: 100;

    .box-notes {
      list-style: none;
      height: 200px;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      li {
        &.first {
          font-weight: 600;
          font-size: 24px;
        }

        &.link {
          color: #fff;
          padding: 10px 20px;
          border-radius: 5px;
          background: #176eff;
          box-shadow: 0 0 35px rgba(140, 150, 160, 0.2);
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    > * {
      animation: rotate 1.4s linear infinite;
    }
    > * > * {
      stroke-dasharray: 88px;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: spinner 1.4s ease-in-out infinite;
      animation-name: spinner;
      animation-duration: 1.4s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      stroke-width: 1;
      stroke-linecap: round;
      position: relative;
    }
  }
  &::after {
    content: "";
    background: #f6f9fe;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  }
  &:hover {
    &::after {
      opacity: 0.6;
    }
  }
}

.output {
  span {
    padding-top: 10px;
    display: block;
    text-align: initial;
  }
  pre {
    background: #e5e5e5;
    color: #2c3e50;
    padding: 14px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;

    &,
    code {
      font-family: "Source Code Pro", monospace;
      max-width: 500px;
      text-align: left;
      white-space: nowrap;
      word-spacing: normal;
      word-break: break-word;
      word-wrap: break-word;
      line-height: 1.5;
      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;
      -webkit-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
      position: relative;
    }
  }
}

input[type="file"] {
  display: block;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes spinner {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
</style>
