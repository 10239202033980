<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addUsersModal' hide-footer>
        <addUsers @closeAddUsers='toggleAddUsers()' @addUsersSuccess='addUsersSuccess()'></addUsers>
      </b-modal>
      <b-modal ref='editUsersModal' hide-footer>
        <editUsers
          :editingUsers='currentUsers'
          @closeEditUsers='toggleEditUsers()'
          @editUsersSuccess='editUsersSuccess()'
        ></editUsers>
      </b-modal>
      <div class='row'>
        <h2>Users</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddUsers()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addUsers from '../../components/addUsers';
import editUsers from '../../components/editUsers';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Email',
                field: 'Email',
                sort: 'asc',
              },
              {
                label: 'Password',
                field: 'Password',
                sort: 'asc',
              },
              {
                label: 'Salt',
                field: 'Salt',
                sort: 'asc',
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
              },
              {
                label: 'ContactNumber',
                field: 'ContactNumber',
                sort: 'asc',
              },
              {
                label: 'UserType',
                field: 'UserType',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addUsersOpen: false,
      editUsersOpen: false,
      currentUsers: {}
    };
  },
  components: {
    addUsers,
    editUsers,
    Datatable,
  },
  created: function() {
    this.getUsers();
    this.addUsersOpen = false;
    this.editUsersOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getUsers() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getUsers', payload);
    },
    editItem(Users) {
      this.toggleEditUsers();
      this.currentUsers = Users;
    },
    toggleAddUsers() {
      if(this.addUsersOpen)
      {
        this.$refs.addUsersModal.hide()
      }
      else{
        this.$refs.addUsersModal.show()
      }
      this.addUsersOpen = !this.addUsersOpen;
    },
    addUsersSuccess() {
      this.toggleAddUsers();
      miniToastr['success']('Users Added', 'Success', 1000, null);
      this.getUsers();
    },
    toggleEditUsers() {
      if(this.editUsersOpen)
      {
        this.$refs.editUsersModal.hide()
      }
      else{
        this.$refs.editUsersModal.show()
      }
      this.editUsersOpen = !this.editUsersOpen;
    },
    editUsersSuccess() {
      this.toggleEditUsers();
      miniToastr['success']('Users Edited', 'Success', 1000, null);
      this.getUsers();
    }
  }
};
</script>
<style scoped>
.UsersThumbnail {
  height: 50px;
}
</style>
