<template>
  <div class='container'>
  <h2>Add A Users</h2>
   <button @click='save()' type='button' class='btn btn-success'>Save</button>
  <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
	<div class='row'>
            <div class=' col-md-4'><label class='' for='Email'>Email</label><input class=' form-control'   
                id='Email' name='Email' v-model='newUsers.Email' placeholder='Email' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Password'>Password</label><input class=' form-control'   
                id='Password' name='Password' v-model='newUsers.Password' placeholder='Password' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Salt'>Salt</label><input class=' form-control'   
                id='Salt' name='Salt' v-model='newUsers.Salt' placeholder='Salt' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Name'>Name</label><input class=' form-control'   
                id='Name' name='Name' v-model='newUsers.Name' placeholder='Name' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='ContactNumber'>ContactNumber</label><input class=' form-control'   
                id='ContactNumber' name='ContactNumber' v-model='newUsers.ContactNumber' placeholder='ContactNumber' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='UserType'>UserType</label><input class=' form-control'   
                id='UserType' name='UserType' v-model='newUsers.UserType' placeholder='UserType' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control'   
                id='LastUpdated' name='LastUpdated' v-model='newUsers.LastUpdated' placeholder='LastUpdated' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
    <br/>
            </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addUsers',
  created: function() {
  },
  data() {
      return {
          newUsers: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    FileUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddUsers')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newUsers,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addUsersSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addUsers', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
